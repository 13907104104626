import React from "react";

const ShoppingBag = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_225_1056)">
                <path d="M9.75001 0.300049C8.80855 0.301108 7.90595 0.67557 7.24024 1.34128C6.57453 2.00699 6.20007 2.90959 6.19901 3.85105V4.04205H2.29901C2.19115 4.04192 2.08706 4.08168 2.00676 4.15367C1.92645 4.22567 1.87561 4.32482 1.86401 4.43205L0.303005 18.6761C0.296411 18.7371 0.302748 18.7989 0.321597 18.8574C0.340447 18.9159 0.371392 18.9698 0.412418 19.0155C0.453444 19.0612 0.503632 19.0978 0.559726 19.1229C0.615821 19.148 0.676565 19.161 0.73801 19.161H18.766C18.8275 19.161 18.8882 19.148 18.9443 19.1229C19.0004 19.0978 19.0506 19.0612 19.0916 19.0155C19.1326 18.9698 19.1636 18.9159 19.1824 18.8574C19.2013 18.7989 19.2076 18.7371 19.201 18.6761L17.637 4.43205C17.6254 4.32482 17.5746 4.22567 17.4943 4.15367C17.414 4.08168 17.3099 4.04192 17.202 4.04205H13.302V3.85105C13.3009 2.90959 12.9265 2.00699 12.2608 1.34128C11.5951 0.67557 10.6915 0.301108 9.75001 0.300049ZM7.07301 3.85105C7.08656 3.15016 7.3745 2.48254 7.87497 1.99166C8.37543 1.50077 9.04848 1.22579 9.74951 1.22579C10.4505 1.22579 11.1236 1.50077 11.624 1.99166C12.1245 2.48254 12.4125 3.15016 12.426 3.85105V4.04205H7.07301V3.85105ZM16.806 4.91705L18.274 18.2871H1.22701L2.69501 4.91705H6.19501V6.54605H7.07001V4.91705H12.423V6.54605H13.289H13.298V4.91705H16.806Z" fill="white" stroke="white" strokeWidth="0.6" />
            </g>
            <defs>
                <clipPath id="clip0_225_1056">
                    <rect width="19.5" height="19.462" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ShoppingBag;