import React, { useState, useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Modal, Button, NamedLink } from '../../../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { bool, func, shape } from 'prop-types';
import { propTypes } from '../../../../util/types';

import { types as sdkTypes } from '../../../../util/sdkLoader';
import config from '../../../../config/configDefault';
import { createResourceLocatorString } from '../../../../util/routes';
import { setInitialValues } from '../../../../containers/CheckoutPage/CheckoutPage.duck';
import { initializeCardPaymentData } from '../../../../ducks/stripe.duck.js';
import { manageDisableScrolling } from '../../../../ducks/ui.duck';
import { createSlug } from '../../../../util/urlHelpers';
import ShoppingBag from './images/ShoppingBag.js';
import { richText } from '../../../../util/richText.js';

import css from './ShoppingCart.module.css';
import { formatMoney } from '../../../../util/currency.js';

const minOrderAmount = process.env.REACT_APP_MIN_CHECKOUT_AMOUNT;
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const { UUID } = sdkTypes;
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const { Money } = sdkTypes;

const ShoppingCartComponent = props => {
  const {
    intl,
    callSetInitialValues,
    routeConfiguration,
    onManageDisableScrolling,
    isAuthenticated,
    currentUser,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [shoppingCartItems, setShoppingCartItems] = useState(currentUser?.attributes?.profile.publicData.shoppingCart?.map(item => {
    return {
      listing: JSON.parse(item.listing),
      checkoutValues: JSON.parse(item.checkoutValues),
    };
  }) || []);

  useEffect(() => {
    if (isAuthenticated) {
      sdk.currentUser
        .show()
        .then(res => {
          // setCurrentUser(res.data.data);
          const shoppingCart = res.data.data.attributes.profile.publicData.shoppingCart;
          if (shoppingCart && shoppingCart?.length > 0) {
            setShoppingCartItems(
              shoppingCart.map(item => {
                return {
                  listing: JSON.parse(item.listing),
                  checkoutValues: JSON.parse(item.checkoutValues),
                };
              })
            );
          }
        })
        .catch(e => {
          if (typeof window !== 'undefined') {
            const shoppingCart = JSON.parse(window.sessionStorage.getItem('shoppingCart'));

            if (shoppingCart && shoppingCart?.length > 0) {
              setShoppingCartItems(
                shoppingCart.map(item => {
                  return {
                    listing: JSON.parse(
                      typeof item.listing === 'string' ? item.listing : JSON.stringify(item.listing)
                    ),
                    checkoutValues: JSON.parse(
                      typeof item.checkoutValues === 'string'
                        ? item.checkoutValues
                        : JSON.stringify(item.checkoutValues)
                    ),
                  };
                })
              );
            }
          }
          return console.log(e);
        });
    }
  }, [isOpen, currentUser]);

  const deleteItem = id => {
    let newShoppingCart = [...shoppingCartItems];

    const indexOfRemovingItem = newShoppingCart.findIndex(item => {
      return item.listing.id.uuid === id;
    });

    if (indexOfRemovingItem > -1) {
      newShoppingCart.splice(indexOfRemovingItem, 1); // 2nd parameter means remove one item only
    }

    if (currentUser) {
      return sdk.currentUser
        .updateProfile({
          publicData: {
            shoppingCart: newShoppingCart.map(item => {
              return {
                listing: JSON.stringify({ ...item.listing }),
                checkoutValues: JSON.stringify({ ...item.checkoutValues }),
              };
            }),
          },
        })
        .then(res => {
          setShoppingCartItems(newShoppingCart);
        })
        .catch(e => console.log(e));
    } else {
      window.sessionStorage.setItem('shoppingCart', JSON.stringify(newShoppingCart));
      setShoppingCartItems(newShoppingCart);
    }
  };

  const add = id => {
    let newShoppingCart = [...shoppingCartItems];

    newShoppingCart.map(item => {
      let newItem = { ...item };

      if (newItem.listing.id.uuid === id) {
        const itemCurrentStock = newItem?.listing.currentStock?.attributes?.quantity;
        const newValue = Number(newItem.checkoutValues.quantity) + 1;
        if (newValue <= itemCurrentStock) {
          newItem.checkoutValues.quantity = (
            Number(newItem.checkoutValues.quantity) + 1
          ).toString();
        }
      }

      return newItem;
    });

    if (currentUser) {
      return sdk.currentUser
        .updateProfile({
          publicData: {
            shoppingCart: newShoppingCart.map(item => {
              return {
                listing: JSON.stringify({ ...item.listing }),
                checkoutValues: JSON.stringify({ ...item.checkoutValues }),
              };
            }),
          },
        })
        .then(res => {
          setShoppingCartItems(newShoppingCart);
        })
        .catch(e => console.log(e));
    } else {
      window.sessionStorage.setItem('shoppingCart', JSON.stringify(newShoppingCart));
      setShoppingCartItems(newShoppingCart);
    }
  };

  const remove = id => {
    let newShoppingCart = [...shoppingCartItems];

    const foundItem = newShoppingCart.find(item => {
      return item.listing.id.uuid === id;
    });

    const isQuantityOne = Number(foundItem.checkoutValues.quantity) === 1;
    if (isQuantityOne) {
      return deleteItem(id);
    } else {
      newShoppingCart.map(item => {
        let newItem = { ...item };

        if (newItem.listing.id.uuid === id) {
          newItem.checkoutValues.quantity = (
            Number(newItem.checkoutValues.quantity) - 1
          ).toString();
        }

        return newItem;
      });

      if (currentUser) {
        return sdk.currentUser
          .updateProfile({
            publicData: {
              shoppingCart: newShoppingCart.map(item => {
                return {
                  listing: JSON.stringify({ ...item.listing }),
                  checkoutValues: JSON.stringify({ ...item.checkoutValues }),
                };
              }),
            },
          })
          .then(res => {
            setShoppingCartItems(newShoppingCart);
          })
          .catch(e => console.log(e));
      } else {
        window.sessionStorage.setItem('shoppingCart', JSON.stringify(newShoppingCart));
        setShoppingCartItems(newShoppingCart);
      }
    }
  };

  const { totalPrice, totalOrderAmount } = useMemo(() => {
    const totalAmount = shoppingCartItems.reduce((sum, item) => {
      return sum + item.listing.attributes.price.amount * Number(item.checkoutValues.quantity ?? 1);
    }, 0);

    return {
      totalPrice: formatMoney(intl, new Money(totalAmount, config.currency)),
      totalOrderAmount: totalAmount,
    };
  }, [shoppingCartItems, intl]);

  const isBelowMininumAmount = totalOrderAmount < Number(minOrderAmount) * 100;

  const toCheckout = () => {
    if (currentUser) {
      const { history } = props;
      const listingId = new UUID(shoppingCartItems[0].listing.id.uuid);
      const listing = shoppingCartItems[0].listing;

      const orderData = shoppingCartItems[0].checkoutValues;

      const restOfShoppingCartItems = [...shoppingCartItems];
      restOfShoppingCartItems.shift();
      orderData.restOfShoppingCartItems = restOfShoppingCartItems;

      const initialValues = {
        listing,
        orderData,
        confirmPaymentError: null,
      };

      const saveToSessionStorage = true;
      callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

      // Clear previous Stripe errors from store if there is any
      initializeCardPaymentData();

      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routeConfiguration,
          { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
          {}
        )
      );
    }
  };

  const shippingItem = shoppingCartItems.find(item => {
    return item.checkoutValues.deliveryMethod === 'shipping';
  });

  const quantityTotal = useMemo(() => {
    return shoppingCartItems.reduce((total, item) => {
      return total + Number(item.checkoutValues.quantity ?? 1);
    }, 0);
  }, [shoppingCartItems]);

  return (
    <>
      <div
        className={css.shoppingCartWrapper}
        onClick={() => { setIsOpen(true) }}
      >
        {shoppingCartItems.length > 0 ? (
          <div className={css.itemsCount}>{quantityTotal}</div>
        ) : null}
        <ShoppingBag />
      </div>

      <Modal
        id="shoppingCartModal"
        isOpen={isOpen}
        onClose={() => { setIsOpen(false) }}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.containerClassName}
        contentClassName={css.contentClassName}
        shoppingCartHeader={
          <span className={css.header}>
            <FormattedMessage id="ShoppingCart.yourBag" />
          </span>
        }
        shoppingCartFooter={shoppingCartItems.length > 0 &&
          <div className={css.bottomWrapper}>
            <div className={css.total}>
              <span className={css.priceLabel}>
                <FormattedMessage id="ShoppingCart.total" />
              </span>

              <span className={css.price}>{totalPrice}</span>
            </div>
            {shippingItem ? <p className={css.infoTextTotal}>Before delivery cost</p> : null}
            {isBelowMininumAmount ? (
              <p className={css.infoText}>* The minimum order amount is ${minOrderAmount}</p>
            ) : null}

            <Button
              className={css.ctaButton}
              type="button"
              disabled={isBelowMininumAmount}
              onClick={toCheckout}
            >
              <FormattedMessage id="ShoppingCart.checkout" />
            </Button>
          </div>
        }
      >

        {shoppingCartItems.length === 0 ? (
          <div className={css.emptyBag}>
            <FormattedMessage id="ShoppingCart.emptyTitle" />
          </div>
        ) : (
          <div className={css.cartItemsWrapper}>
            {shoppingCartItems.map(item => {
              const totalItemAmount =
                item.listing.attributes.price.amount * Number(item.checkoutValues.quantity ?? 1);
              const totalPriceOfItem = new Money(totalItemAmount, config.currency);
              const formattedPrice = formatMoney(intl, totalPriceOfItem)

              const itemImage =
                item.listing.images[0]?.attributes?.variants?.['listing-card-6x']?.url;

              const quantityToDisplay = Number(item.checkoutValues.quantity) > 1 ? `${item.checkoutValues.quantity} x ` : "";

              return (
                <div className={css.cartItem} key={item.listing.id.uuid}>
                  <NamedLink
                    name="ListingPage"
                    params={{
                      id: item.listing.id.uuid,
                      slug: createSlug(item.listing.attributes.title)
                    }}
                  >
                    <img src={itemImage} className={css.cartItemImage} />
                  </NamedLink>
                  {/* <div className={css.buttonsWrapper}>
                      <RemoveIcon
                        className={css.quantityButton}
                        onClick={() => remove(item.listing.id.uuid)}
                      />
                      <AddIcon
                        className={css.quantityButton}
                        onClick={() => add(item.listing.id.uuid)}
                      />
                    </div> */}
                  <div className={css.info}>
                    <div className={css.mainInfo}>
                      <div className={css.itemTitle}>
                        {richText(
                          `${quantityToDisplay}${item.listing?.attributes?.title || ''}`,
                          {
                            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                            longWordClass: css.longWord,
                          }
                        )}
                      </div>
                      <div className={css.productSubCategory}>
                        {item.listing?.attributes?.publicData.productSubCategory || ''}
                      </div>
                      <div className={css.size}>
                        {`SIZE ${item.listing?.publicData?.size || ''}`}
                      </div>
                    </div>
                    <div className={css.cartItemBottom}>
                      <span className={css.price}>{formattedPrice}</span>
                      <div
                        className={css.remove}
                        onClick={() => deleteItem(item.listing.id.uuid)}
                      >
                        <FormattedMessage id="ShoppingCart.remove" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Modal >
    </>
  );
};

ShoppingCartComponent.defaultProps = {
  currentUser: null,
};

ShoppingCartComponent.propTypes = {
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
  callSetInitialValues: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;

  return {
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
});

const ShoppingCart = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ShoppingCartComponent);

export default ShoppingCart;
