import React from "react";
import { LinkedLogo, NamedLink } from "../../../../components";
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './SecondaryBar.module.css';

const SecondaryBar = props => {
    const { config, intl } = props;
    const marketplaceName = config.marketplaceName;

    return (
        <div className={css.root}>
            <LinkedLogo
                className={css.logoLink}
                layout="desktop"
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
                linkToExternalSite={config?.topbar?.logoLink}
            />
            <NamedLink name="SearchPage" className={css.topbarLink}>
                <span className={css.topbarLinkLabel}>
                    <FormattedMessage id="SecondaryBar.whatsNew" />
                </span>
            </NamedLink>
            <NamedLink
                name="SearchPage"
                to={{ search: '?pub_productType=Menswear' }}
                className={css.topbarLink}
            >
                <span className={css.topbarLinkLabel}>
                    <FormattedMessage id="SecondaryBar.mensware" />
                </span>
            </NamedLink>
            <NamedLink
                name="SearchPage"
                to={{ search: '?pub_productType=Womenswear' }}
                className={css.topbarLink}
            >
                <span className={css.topbarLinkLabel}>
                    <FormattedMessage id="SecondaryBar.womensware" />
                </span>
            </NamedLink>
        </div>
    );
};

export default SecondaryBar;